/* eslint-disable react/no-array-index-key */
import React from 'react'
import { graphql } from 'gatsby'

// Components
import SEO from 'components/SEO'
import Layout from 'components/Layout'
import CustomBreadCrumb from 'components/Common/Breadcrumb'
import Content from 'components/Content'
import QuoteBanner from 'components/Diensten/QuoteBanner'
import Team from 'components/Common/Team'
import DienstenLijst from 'components/Common/DienstenLijst'
import ButtonAlt from 'components/Elements/ButtonAlt'

// Third Party
import styled from 'styled-components'
import Plaatjie from '@ubo/plaatjie'

const OverOns = styled.div`
  @media (max-width: 991px) {
    & iframe {
      height: 200px;
    }
  }

  h3 {
    font-size: 20px;
    display: flex;
    align-items: center;

    span {
      margin-left: 10px;
    }
  }
`

const StyledImg = styled(Plaatjie)`
  height: 500px;

  @media (max-width: 991px) {
    height: 350px;
  }
`

const Button = styled(ButtonAlt)`
  width: 264px;
  margin-bottom: 1rem;

  & a {
    margin: 0;
  }
`

const OverOnsTemplate = ({ data: { page }, pageContext }) => (
  <Layout>
    <>
      <SEO seo={page.seo} socialimage={page.pageAbout.banner.image} />
      <OverOns>
        <CustomBreadCrumb
          data={page}
          className="py-2"
          pageContext={pageContext}
        />
        <div className="pb-0 pb-md-2">
          <StyledImg
            big
            loading="eager"
            image={page.pageAbout.banner.image}
            alt=""
          />
        </div>
        <div className="container">
          <div className="row py-5">
            <div className="col-12 col-lg-7 pl-lg-3">
              <h2>{page.pageAbout.inhoudAbout.title}</h2>
              <Content content={page.pageAbout.inhoudAbout.description} />
              <iframe
                loading="lazy"
                src={page.pageAbout.inhoudAbout.videoUrl}
                className="my-4"
                title="video"
                width="100%"
                height="410"
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
              />
              <Content content={page.pageAbout.inhoudAbout.secondDescription} />
            </div>
            <div className="col-12 col-lg-5 pr-lg-3 text-center">
              <Button to="/contact">Neem contact op</Button>
              <DienstenLijst className="d-flex flex-wrap justify-content-center " />
            </div>
          </div>
        </div>
        <QuoteBanner data={page} className="py-5 color-background-lightGrey" />
        <Team className="py-5" />
      </OverOns>
    </>
  </Layout>
)

export default OverOnsTemplate

export const pageQuery = graphql`
  query ($databaseId: Int!) {
    page: wpPage(databaseId: { eq: $databaseId }) {
      title
      databaseId
      pageAbout {
        banner {
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100, width: 2000)
              }
            }
          }
        }
        inhoudAbout {
          title
          description
          videoUrl
          secondDescription
        }
        quote {
          icon {
            localFile {
              publicURL
            }
          }
          text
          image {
            localFile {
              publicURL
            }
          }
        }
        team {
          title
          teamleden {
            ... on WpMedewerker {
              title
              databaseId
              recapMedewerker {
                name
                function
                email
                story
                image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(quality: 100, width: 500)
                    }
                  }
                }
              }
            }
          }
        }
      }
      seo {
        canonical
        cornerstone
        focuskw
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphModifiedTime
        opengraphPublisher
        opengraphPublishedTime
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        title
        twitterDescription
        twitterTitle
        opengraphImage {
          sourceUrl
        }
        twitterImage {
          sourceUrl
        }
      }
    }
  }
`
